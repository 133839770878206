import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_13/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    data: allMdx(
      filter: {
        frontmatter: {
          language: { eq: "PL" }
          type: { eq: "slide-map-3" }
        }
      }
      sort: {
        fields: [frontmatter___order]
        order: ASC
      }
    ) {
      edges { 
        node {
          body
        }
      }
    }
    timelineTitels: mdx(frontmatter: {language: {eq: "PL"}, title: {eq: "timelines2"}}) {
      exports {
        titleArray {
          index
          position
          text
        }
      }
      frontmatter {
        toolTip
      }
    }
    modal_1: allMdx(
      filter: {
        frontmatter: {
          language: { eq: "PL" }
          title: { eq: "modal-4-13-1" }
        }
      }
    ) {
      edges {
        node {
          body
        }
      }
    }
    modal_2: allMdx(
      filter: {
        frontmatter: {
          language: { eq: "PL" }
          title: { eq: "modal-4-13-2" }
        }
      }
    ) {
      edges {
        node {
          body
        }
      }
    }
    quote:  allMdx(filter: {frontmatter: {language: {eq: "PL"}, title: {eq: "map-slider-quote-1"}}}) {
      edges {
        node {
          frontmatter {
            author
            authorInfo
            title
          }
          body
        }
      }
    },
    initialSlide: allMdx(
      filter: {
        frontmatter: {
          language: { eq: "PL" }
          title: { eq: "slide-4-13" }
        }
      }
    ) {
      edges {
        node {
          body
        }
      }
    }
  }
  `);

  return (
    <Template
      titles={query.timelineTitels}
      query={query.data.edges}
      modals={[query.modal_1.edges[0].node, query.modal_2.edges[0].node]}
      initialScreen={query.initialSlide.edges[0].node.body}
      quote={query.quote.edges[0].node}
    />
  );
};


export default Slide;
