import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/pl/chapter_4/sub_13/slide1';

import WrapperMobile from 'src/slides/mobile/pl/chapter_4/sub_13/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Co się działo po zwycięstwie I Bitwa Warszawska " description="Obrona Lwowa, Bój o Zadwórze, Bitwa nad Niemnem – zwycięstwo pod Warszawą nie zakończyło wojny." lang="pl" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

// mobile


const Mobile = () => (
  <>
    <SEO title="Co się działo po zwycięstwie I Bitwa Warszawska " description="Obrona Lwowa, Bój o Zadwórze, Bitwa nad Niemnem – zwycięstwo pod Warszawą nie zakończyło wojny." lang="pl" />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};
export default Page;
